<template>
  <ckeditor
    v-model="ckTexte"
    :editor="editor"
    :config="editorConfig"
    @blur="onBlur"
  />
</template>

<style>
  .ck-editor__editable {
    min-height: 100px;
    max-height: 300px;
   }
</style>

<script>

  import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
  import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials'
  import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold'
  import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic'
  import Indent from '@ckeditor/ckeditor5-indent/src/indent'
  import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock'
  import Link from '@ckeditor/ckeditor5-link/src/link'
  import AutoLink from '@ckeditor/ckeditor5-link/src/autolink'
  import LinkImage from '@ckeditor/ckeditor5-link/src/linkimage'
  import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph'
  import Heading from '@ckeditor/ckeditor5-heading/src/heading'
  import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter'
  import Image from '@ckeditor/ckeditor5-image/src/image'
  import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle'
  import ImageInsert from '@ckeditor/ckeditor5-image/src/imageinsert'
  import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize'
  import AutoImage from '@ckeditor/ckeditor5-image/src/autoimage'
  import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar'
  import Table from '@ckeditor/ckeditor5-table/src/table'
  import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar'
  import ListStyle from '@ckeditor/ckeditor5-list/src/liststyle'
  import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed'

  export default {
    props: {
      texte: {
        type: String,
        default: '',
      },
    },
    data: () => ({
      editor: ClassicEditor,
      ckTexte: '',
      editorConfig: {
        plugins: [
          MediaEmbed,
          Essentials,
          Bold,
          Italic,
          Link,
          AutoLink,
          LinkImage,
          Heading,
          Paragraph,
          Image,
          SimpleUploadAdapter,
          ImageResize,
          ImageInsert,
          AutoImage,
          ImageToolbar,
          ImageStyle,
          Table,
          TableToolbar,
          Indent,
          IndentBlock,
          ListStyle,
        ],
        toolbar: ['heading', '|', 'bold', 'italic', 'link', '|', 'outdent', 'indent', '|', 'numberedList', 'bulletedList', '|', 'insertImage', 'MediaEmbed', 'insertTable', '|', 'undo', 'redo'],
        link: {
          decorators: {
            openInNewTab: {
              mode: 'manual',
              label: 'Ouvrir dans un nouvel onglet',
              attributes: {
                target: '_blank',
                rel: 'noopener noreferrer',
              },
            },
          },
        },
        mediaEmbed: {
          previewsInData: true,
        },
        image: {
          styles: [
            'alignLeft', 'alignCenter', 'alignRight',
          ],
          resizeOptions: [
            {
              name: 'resizeImage:original',
              label: 'Original',
              value: null,
            },
            {
              name: 'resizeImage:75',
              label: '75%',
              value: '75',
            },
            {
              name: 'resizeImage:50',
              label: '50%',
              value: '50',
            },
          ],
          toolbar: [
            'resizeImage',
            '|',
            'imageTextAlternative',
            '|',
            'imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight',
          ],
        },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
          ],
        },
        simpleUpload: {
          uploadUrl: process.env.VUE_APP_SERVEUR_URL + '/api/ckupload',
          // Enable the XMLHttpRequest.withCredentials property.
          withCredentials: true,
          headers: {
            'X-CSRF-TOKEN': 'CSRF-Token',
            Authorization: 'aaa',
          },
        },
      },
    }),
    watch: {
      texte: {
        handler (newValue) {
          if (newValue) { this.ckTexte = newValue }
        },
      },
      ckTexte: {
        handler (newValue) {
          this.$emit('update:texte', this.ckTexte)
        },
      },
    },
    created () {
      this.ckTexte = this.texte
      const token = `Bearer ${this.$store.state.jwttoken}`
      this.editorConfig.simpleUpload.headers.Authorization = token
    },
    methods: {
      onBlur() {
        this.$emit('blur', this)
      },
    },
  }

</script>
